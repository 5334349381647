import React, { useEffect } from 'react'
import { useState } from 'react'
import Modal from 'react-modal';
import request from '../../helpers/request'

export default function ProductChartInfo({ path, style, parentStyle }) {
  const [product, setProduct] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  useEffect(() => {
    request(true).get(`/products?url=${path}`).then(data => {
        console.log('product', data.data)
        setProduct(data.data)
    })
  }, [path])
  return [
      <div style={ parentStyle ? parentStyle : { display: 'flex' }}>
        {
          product ? product.chart_explainer_image_url ? <p style={style ? style : null} onClick={() => setShowInfo(true)}>
            <span className="fas fa-info"></span>
          </p> : null : null
        }
        {
          product ? product.video_explainer_url ? <p style={style ? style : null} onClick={() => setShowVideo(true)}>
            <span class="fa-solid fa-video"></span>
          </p> : null : null
        }
      </div>,
      <>
        <Modal className="card" isOpen={showInfo} style={{width: 'max-content'}} onRequestClose={() => setShowInfo(false)}>
          <p style={{fontWeight: 700}}>Chart Information</p>
          <img src={product ? product.chart_explainer_image_url : undefined} />
        </Modal>
      </>,
      <>
        <Modal className="card" isOpen={showVideo} style={{width: 'max-content'}} onRequestClose={() => setShowVideo(false)}>
          <div style={{display: 'flex', flexDirection: 'column', width: '35vw'}}>
            <p style={{fontWeight: 700}}>Video tutorial</p>
            <video controls autoPlay>
              <source src={product ? product.video_explainer_url : undefined} type='video/webm'/>
            </video>
            <button style={{width: '5vw', marginTop: '10px', marginLeft: 'auto'}} className="button background-primary colour-white" onClick={() => setShowVideo(false) }>
              Close
            </button>
          </div>
        </Modal>
      </>
  ]
}
