import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import request from "../../helpers/request";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import styles from './product-data-filter.module.scss'

const ProductDataFilterRow = ({
  filter,
  filterState,
  handleDynamicFilterChange,
  inputfilter = false,
  excludefilter = false
}) => {
  const [inputFilterIsOpen, setInputFilterIsOpen] = useState(false) 
  const [excludeFilterIsOpen, setExcludeFilterIsOpen] = useState(false) 
  const [expanded, setExpanded] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);
  inputfilter = filter?.selectable ? true : false
  excludefilter = filter?.exclude ? true : false
  if (inputfilter === true && excludefilter === true) {
    return (
      <div className={styles['filter-content']}>
        <div className={styles['filter-row']}>
          <div className={styles['filter-row-title']}>
            {filter.title}
          </div>
          <Select
            isMulti
            options={[...new Set(filter.options.flat())].map((option) => {
              return (
                {label: option.label, value: option}
              )
            })}
            onChange={(selectedOptions) => {
              handleDynamicFilterChange(filter, selectedOptions.map(option => option.value))
              setExcludeFilterIsOpen(true);
            }}
            isSearchable
            isClearable
            placeholder={`Select ${filter.title}`}
            menuIsOpen={excludeFilterIsOpen}
            onMenuOpen={() => setExcludeFilterIsOpen(true)}
            onMenuClose={() => setExcludeFilterIsOpen(false)}
          />
        </div>
      </div>
    )
  }
  if (inputfilter === true) {
    return (
      <div className={styles['filter-content']}>
        <div className={styles['filter-row']}>
          <div className={styles['filter-row-title']}>
            {filter.title}
          </div>
          <Select
            isMulti
            options={[...new Set(filter.options.flat())].map((option) => {
              return (
                {label: option.label, value: option}
              )
            })}
            onChange={(selectedOptions) => {
              handleDynamicFilterChange(filter, selectedOptions.map(option => option.value))
              setInputFilterIsOpen(true);
            }}
            value={filter.options.flat()
              .filter(option => filterState[filter.target_column]?.filter(value => typeof value !== "object" )?.includes(option.value))
              .map((option) => {
              return (
                {label: option.label ?? option.value, value: option}
              )
            })}
            isSearchable
            placeholder={`Select ${filter.title}`}
            menuIsOpen={inputFilterIsOpen}
            onMenuOpen={() => setInputFilterIsOpen(true)}
            onMenuClose={() => setInputFilterIsOpen(false)}
          />
        </div>
      </div>
    )
  } if (excludefilter === true) {
    const handleKeyDown = (event) => {
      if (!inputValue) return;
      switch (event.key) {
        case 'Enter':
        case 'Tab': {
          const newValue = value.concat([{label: inputValue, value: inputValue}])
          setValue(newValue)
          handleDynamicFilterChange(filter, newValue)
          setInputValue('');
          event.preventDefault();
        }
        break
        default:
          // do nothing
      }
    }
    return (
      <div className={styles['filter-content']}>
        <div className={styles['filter-row']}>
          <div className={styles['filter-row-title']}>
            {filter.title}
          </div>
          <CreatableSelect
            components={{ DropdownIndicator: null }}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={(newValue) => {
              setValue(newValue);
              handleDynamicFilterChange(filter, newValue);
            }}
            onInputChange={(newValue) => setInputValue(newValue)}
            onKeyDown={handleKeyDown}
            placeholder={`Create an exclusion`}
            value={value}
          />
        </div>
      </div>
    )
  } 
    return (
      <div className={styles['filter-content']}>
        <div className={styles['filter-row']}>
          <div className={styles['filter-row-title']} onClick={() => setExpanded(_ => !_)} >
            <span className={['fa-solid', expanded ? 'fa-arrow-up-to-line' : 'fa-arrow-down-to-line'].join(' ')} ></span> {filter.title}
          </div>
          {(expanded ? filter.options : []).map((row, index) => {
            return (
              <div className={[styles['filter-row-data'], `items-${filter.row_length}`].join(' ')}>
                {row.map((option) => {
                  return (
                    <button
                      key={`options_${filter.target_column}_${option.value}`}
                      onClick={() => handleDynamicFilterChange(filter, option)
                      }
                      className={
                        filterState[filter.target_column] &&
                        filterState[filter.target_column].length
                          ? filterState[filter.target_column].indexOf(
                              option.value
                            ) !== -1
                            ? "active"
                            : ""
                          : ""
                      }
                    >
                      {option.label || option.value || "N/A"}
                    </button>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    )
}

export default function ProductDataFilter({
  dataFilterEndpoint,
  filterState,
  applyFilter,
  inputFilters = null,
  excludeFilters = null
}) {
  const [filters, setFilters] = useState([]);

  // useEffect(() => {
  //   let _storedFilters = localStorage.getItem(`${window.location.href}_filterState`)
  //   if (_storedFilters) {
  //     _storedFilters = JSON.parse(_storedFilters)
  //     Object.keys(_storedFilters).forEach(_key => {
  //       if (_storedFilters[_key] && _storedFilters[_key].length) {
  //         applyFilter(_key, _storedFilters[_key])
  //       }
  //     }) 
  //   }
  // }, [])

  // useEffect(() => {
  //   localStorage.setItem(`${window.location.href}_filterState`, JSON.stringify(filterState));
  // }, [filterState])

  useEffect(() => {
    request(true)
      .get(dataFilterEndpoint)
      .then((e) => {
        console.log("getDataFilters", e.data);
        if (e.status === 200) {
          const filt = e.data.map((fil) => {
            const newOptions = [];
            fil.options.forEach((opt, index) => {
              if (!newOptions[Math.floor(index / fil.row_length)]) newOptions[Math.floor(index / fil.row_length)] = [];
              newOptions[Math.floor(index / fil.row_length)].push(opt);
            });
            fil.options = newOptions;
            return fil;
          });
          setFilters(filt);
        }
      });
  }, []);

  const handleDynamicFilterChange = (filter, options) => {
    const targetColumn = filter.target_column;
    if (!targetColumn) {
      console.error("Target column is not defined in the filter:", filter);
      return;
    }
  
    let val = filterState[targetColumn] || [];
  
    // Convert single option to an array for consistency
    // const selectedOptions = Array.isArray(options) ? options : [options];

    const excludeValuesIndex = val.findIndex(value => value.excludeValues)
    const excludeValues = excludeValuesIndex !== -1 ? val[excludeValuesIndex].excludeValues : null

    if (filter.exclude) {
      if (options.length === 0) {
        val = val.filter(value => !value.excludeValues)
      } else {
        options = options.map(option => option.value)
        if (excludeValuesIndex !== -1) {
          val[excludeValuesIndex].excludeValues =  options
        } else {
          val = val.concat([{excludeValues: options}])
        }
      }
      applyFilter(targetColumn, val)
      return
    }

    if (options.length === 0) {
      val.length = 0;
    } else if (options.length) {
      console.log("add");
      val = options.map(selectedOption => selectedOption.value);
    } else {
        console.log("def");
        const newOptionValue = options.value;
    
        // Check if the new option is already in val
        const isOptionInVal = val.includes(newOptionValue);
    
        if (isOptionInVal) {
          // Remove the option if it's already in val
          val = val.filter(optionValue => optionValue !== newOptionValue);
        } else {
          // Add the option if it's not in val
          val = [...val, newOptionValue];
        }
    }

    if (excludeValuesIndex !== -1) {
      val = val.concat([{excludeValues: excludeValues}]); 
    }
  
    applyFilter(filter.target_column, val);
  };

  const sortedFilters = useMemo(() => {
    return filters.sort((a, b) => {
      return b.order > a.order ? 1 : -1
    })
  }, [filters])

  return sortedFilters.map((filter) => {
    return (
      <>
        <ProductDataFilterRow 
        key={`data_filter_${filter.id}`} 
        handleDynamicFilterChange={handleDynamicFilterChange} 
        filter={filter} 
        filterState={filterState} 
        inputfilter={inputFilters && inputFilters.find(filterName => filterName === filter.internal_name) ? true : false} 
        excludefilter={excludeFilters && excludeFilters.find(filterName => filterName === filter.internal_name) ? true : false}
        />
      </>
    );
  });
}
