import React, { Component } from 'react'
import Card from '../card/card'

import request from '../../helpers/request';
import endpoints from '../../helpers/endpoints';
import session from '../../stores/session';
import { view } from '@risingstack/react-easy-state';
import { Redirect, Link, withRouter } from 'react-router-dom';
import './login.scss';

export default view(withRouter(class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stage: 'login',
            form: {},
            error: null,
            action: null,
            signature: null,
            loading: false
        }

        this.updateFormValue = this.updateFormValue.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
    }

    updateFormValue(e) {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value ? e.target.value : undefined
            }
        })
    }

    formSubmit(e) {
        this.setState({loading: true})
        e.preventDefault();
        const stage = this.state.stage;

        if (stage === "login") {
            if (e.nativeEvent.submitter.id === 'login') {
                request().post(endpoints.LOGIN, this.state.form).then(e => {
                    this.setState({
                        stage: '2fa',
                        action: e.data.action_id,
                        signature: e.data.signature,
                        error: null,
                        form: {},
                        loading: false
                    });
                }).catch(e => {
                    this.setState({
                        error: e.response.data.message
                    });
                    this.setState({loading: false})
                });
            } else if (e.nativeEvent.submitter.id === 'azurelogin') {
                console.log(session.company)
                console.log(`${sessionStorage.getItem('api-path')}/auth/azure`)
                window.location.href = `${sessionStorage.getItem('api-path')}/auth/azure?redirect=${window.location.protocol}//${window.location.host}/login/azure`
            }
        } else if (stage === "2fa") {
            request().post(endpoints.TWOFA, {
                ...this.state.form,
                signature: this.state.signature,
                action_id: this.state.action
            }).then(e => {
                this.setState({loading: false})
                sessionStorage.setItem('user-token', e.data.token);
                session.token = e.data.token;
                session.fetch();

                if (e.data.user.password_expires_in && e.data.user.password_expires_in < 15) {
                    if (e.data.user.company) {
                        return this.props.history.push('/account')
                    }
                }

                if (e.data.user.company) {
                    return this.props.history.push('/portal')
                }

                return this.props.history.push('/admin/portal')
            }).catch(e => {
                this.setState({loading: false})
                console.log('error', e)
                if (!e.response) return
                this.setState({
                    error: e.response.data.message
                });
            });
        } else {
            this.setState({
                stage: 'login'
            });
        }
    }

    azureLogin() {
        console.log(session.company)
        console.log(`${sessionStorage.getItem('api-path')}/auth/azure`)
        window.location.href = `${sessionStorage.getItem('api-path')}/auth/azure?redirect=${window.location.protocol}//${window.location.host}/login/azure`
    }

    render() {
        if (session.token) {
            const alive = session.isAlive();

            if (alive === true) {
                if (session.company === null) {
                    return (<Redirect to="/admin-portal"/>);
                } 
                    return (<Redirect to="/portal" />);
            } if (alive === false) {
                return (<Redirect to="/login"/>);
            } 
                alive.then(r => {
                    if (session.company === null) {
                        return (<Redirect to="/admin-portal"/>);
                    } 
                        return (<Redirect to="/portal" />);
                });
        }

        return (
            <div className="login-wrapper">
                {
                    this.state.stage === "login" ? <Card>
                        <div className="">
                            <p className="font-weight-600 font-size-2 font-align-center">Sign In</p>
                        </div>
                        <p>Please enter your username and password.</p>
                        { this.state.error ? <p className="error">{ this.state.error }</p> : '' }
                        <form className="grid grid-gap-15" onSubmit={this.formSubmit}>
                            <input onChange={this.updateFormValue} name="email" placeholder="Email" className="input" type="text" required/>
                            <input onChange={this.updateFormValue} name="password" placeholder="Password" className="input" type="password" required/>
                            <button id="login" className="button background-primary colour-white" disabled={this.state.loading}>
                                {
                                    this.state.loading ? <>
                                        <i className='fas fa-loader fa-fw fa-spin'></i>
                                    </> : <>
                                        Sign In
                                    </>
                                }
                            </button>
                            {
                                session.company && session.company.can_azure_login ? <>
                                    <button id="azurelogin" onClick={this.azureLogin} className="button background-primary colour-white" disabled={this.state.loading}>
                                        <span className='fab fa-microsoft'></span> Login with Azure AD
                                    </button>
                                </> : null
                            }
                        </form>
                        <Link to="/reset" className="forgot-password">
                            <i className="fas fa-lock-alt"></i><i className="fas fa-unlock-alt"></i>Forgot your password?
                        </Link>
                    </Card> : ''
                }
                {
                    this.state.stage === "2fa" ? <Card>
                        <h1>2-Step Verification</h1>
                        <p>Please enter your Two Factor Authentication code, you should receive it via email within a few minutes.</p>
                        { this.state.error ? <p className="error">{ this.state.error }</p> : '' }
                        <form className="grid grid-gap-15" onSubmit={this.formSubmit}>
                            <input onChange={this.updateFormValue} name="token" placeholder="Authentication Code" className="input" type="text" required/>
                            <button onClick={this.formSubmit} className="button background-primary colour-white">
                                {
                                    this.state.loading ? <>
                                        <i className='fas fa-loader fa-fw fa-spin'></i>
                                    </> : <>
                                        Continue
                                    </>
                                }
                            </button>
                        </form>
                    </Card> : ''
                }
            </div>
        )
    }
}));