import React, { Component } from 'react'
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import Card from '../../components/card/card'
import request from '../../helpers/request'
import endpoints from '../../helpers/endpoints'
import { withRouter, matchPath } from 'react-router-dom'

export default withRouter(class ProductPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            match: matchPath(props.history.location.pathname, {
                path: '/admin/portal/products/:product'
            }),
            product: {
                id: null,
                name: '',
                description: '',
                icon: '',
                available: false,
                chart_explainer_image_url: '',
                enable_house_icon: true,
            },
            message: null,
            errors: {
                name: [],
                description: [],
                icon: '',
            },
            enableDeleteField: false,
        }
    }

    fetchProduct = (state) => {
        //console.log(this.state);
        request(true).get(endpoints.ADMIN_PRODUCTS_INDEX + '/' + this.state.match.params.product).then(r => {
            this.setState({
                product: {
                    ...r.data,
                    available: r.data.available === 1
                },
                ...state
            });
        }).catch(r => {
            //console.log(r);
        });
    }

    componentDidMount() {
        this.fetchProduct();
    }

    updateFormValue = (e) => {
        let value = e.target.value ? e.target.value : null

        if (e.target.value === "true") {
            value = true;
        } else if (e.target.value === "false") {
            value = false;
        }

        this.setState({
            product: {
                ...this.state.product,
                [e.target.name]: value
            }
        });
    }

    formSubmit = () => {
        request(true).post(endpoints.ADMIN_PRODUCTS_INDEX + '/' + this.state.match.params.product, this.state.product).then(r => {
            //console.log(this.state.product);
            //console.log(r);
        }).catch(r => {
            //console.log(r.response);
            this.setState({ message: 'Error Updating Product', errors: r.response.data.errors });
        })
    }

    deleteProduct = () => {
        if (this.state.enableDeleteField) {
            request(true).delete(endpoints.ADMIN_PRODUCTS_INDEX + '/' + this.state.match.params.product).then(r => {
                //console.log(r);
                this.props.history.goBack();
            }).catch(r => {
                console.error(r);
            })
        } else {
            this.setState({ enableDeleteField: true });
        }
    }

    render() {
        return (
            <div className="grid grid-gap-20">
                <Navigation />
                { this.state.product.id !== null ?
                <Container>
                    <div className="flex middle">
                        <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>{ this.state.product.name }</p>
                    </div>
                        <Card>
                            <div className="grid grid-gap-15">
                                <div className="form-row">
                                    <label htmlFor="name">Name:</label>
                                    <input onChange={this.updateFormValue} name="name" placeholder="Name" className="input" type="text" value={this.state.product.name} required/>
                                    { this.state.errors.name ? this.state.errors.name.map((value, index) => {
                                        return (
                                            <p key={index}>
                                                { value }
                                            </p>
                                        )
                                    }) : <></>}
                                </div>
                                <div className="form-row">
                                    <label htmlFor="icon">Icon:</label>
                                    <input onChange={this.updateFormValue} name="icon" placeholder="Icon" className="input" type="text" value={this.state.product.icon} required/>
                                    { this.state.errors.name ? this.state.errors.name.map((value, index) => {
                                        return (
                                            <p key={index}>
                                                { value }
                                            </p>
                                        )
                                    }) : <></>}
                                </div>
                                <div className="form-row">
                                    <label htmlFor="description">Description:</label>
                                    <input onChange={this.updateFormValue} name="description" placeholder="Description" className="input" type="text" value={this.state.product.description} required/>
                                    { this.state.errors.description ? this.state.errors.description.map((value, index) => {
                                        return (
                                            <p className="error" key={index}>
                                                { value }
                                            </p>
                                        )
                                    }) : <></>}
                                </div>
                                <div className="form-row">
                                    <label htmlFor="description">Chart Explination Image:</label>
                                    <input onChange={this.updateFormValue} name="chart_explainer_image_url" placeholder="Chart Explination Image" className="input" type="text" value={this.state.product.chart_explainer_image_url} required/>
                                    { this.state.errors.chart_explainer_image_url ? this.state.errors.chart_explainer_image_url.map((value, index) => {
                                        return (
                                            <p className="error" key={index}>
                                                { value }
                                            </p>
                                        )
                                    }) : <></>}
                                </div>
                                <div className="form-row">
                                    <label htmlFor="description">Show House Icon on Portal:</label>
                                    <div className="item">
                                        True: <input onChange={this.updateFormValue} name="enable_house_icon" className="input" type="radio" value={true} checked={this.state.product.enable_house_icon}/>
                                    </div>
                                    <div className="item">
                                        False: <input onChange={this.updateFormValue} name="enable_house_icon" className="input" type="radio" value={false} checked={!this.state.product.enable_house_icon}/>
                                    </div>
                                    { this.state.errors.enable_house_icon ? this.state.errors.enable_house_icon.map((value, index) => {
                                        return (
                                            <p className="error" key={index}>
                                                { value }
                                            </p>
                                        )
                                    }) : <></>}
                                </div>
                                <div className="form-row">
                                    <label htmlFor="available">Available:</label>
                                    <div className="item">
                                        True: <input onChange={this.updateFormValue} name="available" className="input" type="radio" value={true} checked={this.state.product.available}/>
                                    </div>
                                    <div className="item">
                                        False: <input onChange={this.updateFormValue} name="available" className="input" type="radio" value={false} checked={!this.state.product.available}/>
                                    </div>
                                    { this.state.errors.available ? this.state.errors.available.map((value, index) => {
                                        return (
                                            <p className="error" key={index}>
                                                { value }
                                            </p>
                                        )
                                    }) : <></>}
                                </div>
                                <button onClick={this.formSubmit} className="button background-primary colour-white">Update</button>
                                <p className="text-center">{ this.state.message }</p>
                            </div>
                        </Card>
                        <div class={ this.state.enableDeleteField ? 'delete enabled' : 'delete disabled'} style={{ textAlign: 'center', marginTop: '40px' }}>
                            <button onClick={this.deleteProduct} className="button background-primary colour-white">
                                <span style={{ marginRight: '20px' }}>{ this.state.enableDeleteField ? 'Are you sure?' : 'Delete This Product' }</span>
                                <i className="fa fa-unlock" aria-hidden="true"></i>
                                <i className="fa fa-lock" aria-hidden="true"></i>
                            </button>
                        </div>
                </Container>
                : <></>
                }
                <Footer />
            </div>
        )
    }
})
