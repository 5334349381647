import React from 'react';
import Modal from 'react-modal';
import { useEffect } from 'react';
import request from '../../helpers/request';
import './bulkAllocate.scss';
import Select from 'react-select';

export default function BulkBuy ({ modalIsOpen, closeModal, filters, date, bulkEndpoint, workflowUsersEndpoint, mode,  ...props })
{
    const [step, setStep] = React.useState(1);
    const [bulkCount, setBulkCount] = React.useState(0);
    const [bulkLimit, setBulkLimit] = React.useState(0);
    const [buyNowEnabled, setBuyNowEnabled] = React.useState(true);
    const [users, setUsers] = React.useState([]);
    const [targetUser, updateTargetUser] = React.useState(null);
    const [error, setError] = React.useState(undefined);

    useEffect(() => {
        setStep(1);
    }, [modalIsOpen, setStep]);

    useEffect(() => {
        if (step === 1 && modalIsOpen) {
          request(true).get(bulkEndpoint, {
                params: {
                    date: date,
                    filter: filters,
                    mode: mode
                }
            }).then(r => {
                setBulkCount(r.data);
                setBulkLimit(r.data);
            }).catch(e => {
                console.log(e);
            })
        }
    }, [step, modalIsOpen, filters, date, bulkEndpoint]);

    const confirmPurchase = () => {
        setBuyNowEnabled(false);
        buy();
    }

    const buy = () => {
        request(true).post(bulkEndpoint + '/allocate', {
            date: date,
            filter: filters,
            limit: bulkLimit,
            target: targetUser.value,
            mode: mode
        }).then(r => {
            window.location.reload();
        }).catch(e => {
            console.log(e);
        });
    }

    const formChange = (value = 0) => {
        value = Number(value)
        if (value === "") return setBulkLimit(0)

        if (value > bulkCount) {
            value = bulkCount;
        }

        setBulkLimit(value);
    }

    const firstBuyStep = () => {
        console.log('firstBuyStep', bulkLimit, bulkLimit === 0, bulkLimit === '0')
        if (bulkLimit === 0) return setError('You cannot allocate 0 customers, you must allocate 1 or more.')
        setError(undefined)
        setStep(2)
    }

    React.useEffect(() => {
        request(true).get(workflowUsersEndpoint).then(r => {
            setUsers(r.data);
        }).catch(e => {
            console.error(e);
        })
    }, [workflowUsersEndpoint]);

    return (
        <Modal
                    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                    className="card bulk-allocate"
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Bulk Allocate COT Alert Data"
                    appElement={document.getElementById('root')}
            >
            { step === 1 ?
                <>
                    <p>
                        The selected data set includes { bulkCount } customers. How many would you like to allocate?
                    </p>
                    <small>
                        If you reduce this number then customers with higher occupier scores will be allocated first.
                    </small>
                    <small>
                        Customers with an occupier score of 0 cannot be allocated.
                    </small>
                    {error ? <p className="colour-yellow"><strong>{error}</strong></p> : null} 
                    <div className="input">
                        <label>Allocate Limit:</label>
                        <input type="number" name="bulk-limit" max={ bulkCount } value={ bulkLimit } onChange={ (e) => formChange(e.target.value) }/>
                    </div>
                    <div className="input">
                        <label>Allocate To:</label>
                        <Select options={users} onChange={ updateTargetUser }/>
                    </div>
                    <div className="grid-2">
                        <button className="button cancel" onClick={ closeModal }>Cancel</button>
                        <button className="button confirm" onClick={ () => firstBuyStep() } disabled={targetUser === null}>Confirm</button>
                    </div>
                </>
            :
            <>
                <p>
                    You have selected { bulkLimit } accounts to allocate to { targetUser.label }, please press 'Allocate Now' to allocate these accounts.
                </p>
                <div className="grid-2">
                    <button className="button cancel" onClick={ closeModal }>Cancel</button>
                    <button className="button confirm" onClick={ confirmPurchase } disabled={ !buyNowEnabled }>
                        {
                            !buyNowEnabled ?
                                <div className="spinner">
                                    <i className="fa fa-spinner fa-spin"></i>
                                </div>
                            : 'Allocate Now'
                        }
                    </button>
                </div>
            </>
            }
        </Modal>
    )
}
