// Modules
import React, { Component } from 'react'
import { BrowserRouter, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { view } from '@risingstack/react-easy-state';
import axios from 'axios';

// Local
import sessionState from './stores/session';
import session from './stores/session';
import endpoints from './helpers/endpoints';

// Pages

// Global Pages
import Reset from './pages/reset';
import LoginPage from './pages/login';
import Logout from './pages/logout';
import ActivatePage from './pages/activate';
import Account from './pages/account';
import DoReset from './pages/doReset';
import VersionHistory from './pages/version.js';

// User Pages
import PortalPage from './pages/portal';

// Admin
import Admin from './pages/admin/index';
import AdminCompanies from './pages/admin/companies';
import AdminCompany from './pages/admin/company';
import AdminUser from './pages/admin/user';
import Users from './pages/admin/users';
import ProductsPage from './pages/admin/products';
import ProductPage from './pages/admin/product';
import Audits from './pages/admin/audits';
import Usage from './pages/admin/usage.jsx';
import Translations from './pages/admin/translations';
import Tutorials from './pages/admin/tutorials';
import CompanySelect from './pages/admin/companySelect';
import DataFilters from './pages/admin/filters'
import DataFilter from './pages/admin/filter';

// Debug
import VersionPage from './pages/version'

// Background
import IdleTimer from './components/idleTimer/IdleTimer';

//VCA
import vacantCOTAlerts from './pages/modules/VacantCOTAlerts/vacantCOTAlerts';
import vacantCOTAlertsSearch from './pages/modules/VacantCOTAlerts/vacantCOTAlertsSearch';
import VacantCOTAlertsWorkflow from './pages/modules/VacantCOTAlerts/VacantCOTAlertsWorkflow';


//
import Groupings from './pages/admin/groupings';
import Grouping from './pages/admin/grouping';

//
import LoadingPage from './pages/loading';
import BrandingPage from './pages/admin/branding';

import AzureLoginPage from './pages/azureLogin';
import VacantCOTAlertsDashboard from './pages/modules/VacantCOTAlerts/VacantCOTAlertsDashboard';

window.Pusher = require('pusher-js')

export default view(class Router extends Component {
    state = {
        loaded: false,
        stage: 'Detecting Environment'
    }

    componentDidMount() {
        const a = axios.create({
            baseURL: endpoints.BASE
        });

        a.get(endpoints.DOMAIN + '?domain=' + window.location.hostname, {
            timeout: 7000
        }).then(e => {
            this.setState({ stage: 'Configuring App' })
            if (e.data.api_url) {
                sessionStorage.setItem('api-path', e.data.api_url);
            }

            if (e.data.login_video_url) {
                sessionStorage.setItem('login-video-path', e.data.login_video_url);
            }

            if (e.data.frontend_name) {
                sessionStorage.setItem('frontend-name', e.data.frontend_name);
            }

            if (e.data.branding) {
                document.title = `IDenteq ${e.data.branding.name}`
                console.log('Got branding')
                const vars = document.querySelector(':root')
                Object.keys(e.data.branding).filter(_ => _.indexOf('colour__') !== -1).forEach(colour => {
                    const c = colour.replace('colour', '').replaceAll('_', '').replaceAll(' ', '-').toLocaleLowerCase();
                    console.log(`--${c}`, e.data.branding[colour])
                    vars.style.setProperty(`--${c}`, e.data.branding[colour])
                })
                // --primary: var(--primary);
                // --secondary: #445468;
                // --background: #F7FCFF;
                // --gray: #A5A5A5;
                // --light-gray: #F7F7F7;
                // --white: #ffffff;
                // --black: #000000;
                // --logogreen: #009245;
                // --red: #f14014;
                // --yellow: #F19A14;
                // --blue: #118AB2;
                // --teal: #06D6A0;
                // --cobalt: #445469;

                if (e.data.branding.logo) {
                    sessionStorage.setItem('logo-url', e.data.branding.logo)
                }
                sessionState.company.can_azure_login = e.data.can_azure_login
            }

            console.log('Retrieved API Path: ' + sessionStorage.getItem('api-path'));

            setTimeout(() => {
                this.setState({
                    loaded: true
                })
            }, 1000);
        }).catch(() => {
            this.setState({
                stage: 'Failed to get environment, contact support'
            })
        })
    }

    render() {
        return (
            <div className="wrapper">
                <BrowserRouter>
                    <Switch>
                        {/* <Route component={GapSitesAlerts} /> */}
                        {
                            !this.state.loaded ? <Route component={() => <LoadingPage stage={this.state.stage} />} /> : null
                        }
                        <Route path="/login/azure" component={AzureLoginPage} />
                        <Route path="/login" component={LoginPage} />
                        <Route path="/activate" component={ActivatePage} />
                        <Route path="/reset" component={Reset} />
                        <Route path="/auth/reset" component={DoReset} />
                        <Route path="/version" component={VersionPage} />

                        {/* If not logged in redirect */}
                        {
                            !sessionState.token ? <Redirect to={`/login?${window.location.pathname}`} /> : ''
                        }

                        <ProtectedRoute path="/version-history" component={VersionHistory} />

                        <ProtectedRoute path="/account" admin={null} exact={true} component={Account}/>

                        <ProtectedRoute path="/portal" exact={true} component={vacantCOTAlerts} />
                        <ProtectedRoute path="/portal/vacant-cot-alerts/search" exact={true} component={vacantCOTAlertsSearch} />
                        <ProtectedRoute path="/portal/vacant-cot-alerts/workflow" exact={true} component={VacantCOTAlertsWorkflow} />
                        <ProtectedRoute path="/portal/vacant-cot-alerts/dashboard" exact={true} component={VacantCOTAlertsDashboard} />

                        {/* <ProtectedRoute path="/portal" exact={true} component={PortalPage} /> */}

                        <ProtectedRoute path="/admin/portal/company-select/:configuration" admin={true} exact={true} component={CompanySelect} />
                        <ProtectedRoute path="/admin/portal/companies/:company/translate" admin={true} exact={true} component={Translations} />
                        <ProtectedRoute path="/admin/portal/companies/:company/tutorials" admin={true} exact={true} component={Tutorials} />
                        <ProtectedRoute path="/admin/portal/companies/:company" admin={true} exact={true} component={AdminCompany} />
                        <ProtectedRoute path="/admin/portal/companies" admin={true} exact={true} component={AdminCompanies} />

                        <ProtectedRoute path="/admin/portal/branding/:id" admin={true} exact={true} component={BrandingPage} />
                        <ProtectedRoute path="/admin/portal/branding" admin={true} exact={true} component={BrandingPage} />

                        <ProtectedRoute path="/admin/portal/users/:user" admin={session.user.permissions.indexOf('ADMIN-CREATE-USER') !== -1 ? false : true} exact={true} component={AdminUser} />
                        <ProtectedRoute path="/admin/portal/users" admin={true} exact={true} component={Users} />

                        <ProtectedRoute path="/admin/portal/products" admin={true} exact={true} component={ProductsPage} />
                        <ProtectedRoute path="/admin/portal/products/:product" admin={true} exact={true} component={ProductPage} />

                        <ProtectedRoute path="/admin/portal/filters/:id" admin={true} exact={true} component={DataFilter} />
                        <ProtectedRoute path="/admin/portal/filters" admin={true} exact={true} component={DataFilters} />

                        <ProtectedRoute path="/admin/portal/groupings/:id" admin={true} exact={true} component={Grouping} />
                        <ProtectedRoute path="/admin/portal/groupings" admin={true} exact={true} component={Groupings} />

                        <ProtectedRoute path="/admin/portal/audits" admin={true} exact={true} component={Audits} />

                        <ProtectedRoute path="/admin/portal/usage" admin={true} exact={true} component={Usage} routeTarget={"Usage"} />
                        <ProtectedRoute path="/admin/portal/Purchases" admin={true} exact={true} component={Usage} routeTarget={"Purchases"} />

                        <ProtectedRoute path="/admin/portal" admin={true} exact={true} component={Admin} />

                        <Route path="/logout" component={Logout} />
                        { sessionState.company ?
                            <Redirect to="/portal" />
                        :
                            <Redirect to="/admin/portal" />
                        }
                    </Switch>
                </BrowserRouter>
            </div>
        )
    }
})

const ProtectedRoute = withRouter(view(class ProtectedRoute extends Component {
    constructor(props) {
        super(props);

        const data = Object.assign({}, props);
        delete data.component;

        this.state = {
            authenticated: null,
            props: data
        }
    }

    componentDidMount() {
        const alive = sessionState.isAlive();
        const admin = this.props.admin ?? false;

        if (alive === true) {
            if (admin === null) {
                this.updateAuthState(true);
            }

            this.updateAuthState((sessionState.company === null) === admin);
        } else if (alive === false) {
            this.updateAuthState(false);
        } else {
            alive.then(r => {
                if (admin === null) {
                    this.updateAuthState(true);
                }

                this.updateAuthState((sessionState.company === null) === admin);
            });
        }
    }

    updateAuthState = (auth) => {
        this.setState({
            authenticated: auth
        }, () => {
            if (this.state.authenticated === false) {
                sessionState.reset();
                this.props.history.push('/login');
            }
        });
    }

    render() {
        return (
            <Route {...this.state.props} render={ props => {
                return (
                    (
                        this.state.authenticated === true ? (
                            <>
                                <IdleTimer timeout={sessionState?.company?.idle_timeout || 20}/>
                                <this.props.component routeTarget={this.props.routeTarget} />
                            </>
                        ) : <></>
                    )
                )
            }}/>
        );
    }
}))
