import React, { Component } from 'react';
import request from '../../helpers/request'
import endpoints from '../../helpers/endpoints'
import Card from '../card/card'
import './login.scss';

export default class Reset extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            formState: {
                email: '',
            }
        }
    }

    formSubmit = () => {
        request(false).post(endpoints.RESET_REQUEST, {
            email: this.state.formState.email
        }).catch(r => {
            console.error(r);
        });

        this.setState({ message: 'If a user exists with this email, then a password reset link will be sent.' });
    }

    enterKeyDown = (e) => {
        if (e.which === 13) {
            this.formSubmit();
        }
    }

    render() {
        return (
            <div className="login-wrapper">
                <Card>
                    <div className="">
                        <p className="font-weight-600 font-size-2 font-align-center">Reset Password</p>
                    </div>
                    <p>Please enter your email, and we'll send you a link to reset your password!</p>

                    <div className="grid grid-gap-15">
                        <input onKeyPress={ this.enterKeyDown } onChange={ (e) => { this.setState({ formState: { email: e.target.value }}) } } name="email" placeholder="Email" className="input" type="text" required/>
                        <button onClick={this.formSubmit} className="button background-primary colour-white">Reset Password</button>
                    </div>

                    { this.state.message ? <p className="">{ this.state.message }</p> : '' }
                </Card>
            </div>
        );
    }
}
