import React, { Component } from 'react'
import Navigation from '../components/navigation/navigation'
import Footer from '../components/footer/footer'
import Container from '../components/container/container'
import request from '../helpers/request'
import endpoints from '../helpers/endpoints'
import { withRouter } from 'react-router-dom'
import session from '../stores/session'

export default withRouter(class AdminVersionHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changes: null,
            versionNumbers: null,
            loading: true,
            editInput: null,
            editMode: false,
            noteToEdit: null,
            selectedVersionNumber: null,
            limit: 5,
            newNoteInput: null,
            newVersionCode: null,
            createNewNoteMode: false,
            versionCode: null
        }
    }

    componentDidMount() {
        Promise.all([
            request(true).get(endpoints.ADMIN_VERSION_HISTORY_CHANGES),
            request(true).get(endpoints.ADMIN_VERSION_HISTORY_VERSION_NUMBERS)
        ]).then(([changes, versionNumbers]) => {
            if (!versionNumbers.data || versionNumbers.data.length == 0) window.alert("No results found");
            this.setState({
                loading: false,
                changes: changes.data,
                versionNumbers: versionNumbers.data,
                selectedVersionNumber: versionNumbers.data[0]
            })
        })
        request(true).post(endpoints.ADMIN_VERSION_HISTORY_SEEN_CHANGES);
    }

    updateResults = () => {
        request(true).get(endpoints.ADMIN_VERSION_HISTORY_CHANGES + `?Version_Number=${this.state.selectedVersionNumber}&limit=${this.state.limit}`).then(r => {
            if (!r.data || r.data.length == 0) window.alert("No results found");
            this.setState({
                changes: r.data
            })
        })
    }

    setEditMode = (id) => {
        this.state.editMode ?
            this.setState({
                editMode: false,
                noteToEdit: null,
                editInput: null,
                versionCode: null,
            })
        :
            this.setState({
                editMode: true,
                editInput: null,
                versionCode: null,
                noteToEdit: id
            })
    }

    submitChanges = (e) => {
        if (this.state.versionCode && !/^\d+\.\d+\.\d+$/.test(this.state.versionCode)) {
            window.alert('Version codes must follow a semantic versioning pattern such as 1.2.3')
            return
        }
        const changeColumn = e.target.title;
        request(true).post(endpoints.ADMIN_VERSION_HISTORY_EDIT, 
            {
                ID: this.state.noteToEdit, 
                [changeColumn]: this.state.editInput, 
                Version_Number: this.state.versionCode ? this.state.versionCode : this.state.changes[this.state.changes.findIndex(change => change.id === this.state.noteToEdit)].Version_Number
            }).then(r => {
                if (r.status === 200) {
                    request(true).get(endpoints.ADMIN_VERSION_HISTORY_VERSION_NUMBERS).then(r => {
                        this.setState({
                            versionNumbers: r.data,
                            selectedVersionNumber: r.data.includes(this.state.versionCode) ? this.state.versionCode : r.data[0]
                        })
                    }).then(() => {
                        this.setState({
                            noteToEdit: null,
                            versionCode: null,
                            editMode: false
                        });
                        this.updateResults()
                    });
                } else {
                    window.alert('Failed to edit note');
                    this.setState({
                        noteToEdit: null,
                        editMode: null
                    })
                }
        });
    }

    submitNewNote = (e) => {
        if (!this.state.newNoteInput || !this.state.newVersionCode) {
            window.alert('Please ensure your new note has both content and a version code')
            return
        }
        if (!this.state.newVersionCode || !/^\d+\.\d+\.\d+$/.test(this.state.newVersionCode)) {
            window.alert('Version codes must follow a semantic versioning pattern such as 1.2.3')
            return
        }
        request(true).post(endpoints.ADMIN_VERSION_HISTORY_INSERT, 
            {
                [e.target.title]: this.state.newNoteInput, 
                Version_Number: this.state.newVersionCode
            }).then(r => {
                if (r.status === 200) {
                    request(true).get(endpoints.ADMIN_VERSION_HISTORY_VERSION_NUMBERS).then(r => {
                        this.setState({
                            versionNumbers: r.data,
                            selectedVersionNumber: r.data.includes(this.state.newVersionCode) ? this.state.newVersionCode : r.data[0]
                        })
                    }).then(() => {
                        this.setState({
                            newNoteInput: null,
                            newVersionCode: null,
                            createNewNoteMode: false
                        });
                        this.updateResults()
                    });
                } else {
                    window.alert('Failed to submit note');
                    this.setState({
                        createNewNoteMode: null,
                        noteToEdit: null
                    })
                }
        });
    }

    deleteChanges = (id) => {
        const deleteThis = window.confirm('Are you sure you want to delete this note?');
        if (deleteThis) {
            request(true).post(endpoints.ADMIN_VERSION_HISTORY_DELETE, {ID: id}).then(r => {
                if (r.status === 200) {
                    request(true).get(endpoints.ADMIN_VERSION_HISTORY_VERSION_NUMBERS).then(r => {
                        this.setState({
                            versionNumbers: r.data,
                            selectedVersionNumber: r.data.includes(this.state.selectedVersionNumber) ? this.state.selectedVersionNumber : r.data[0]
                        })
                    }).then(() => {
                        this.updateResults()
                    });
                } else {
                    window.alert('Failed to delete note');
                }
            });
        }
    }

  updateEditInput = (e) => {
        this.setState({
            editInput: e.target.value
        })
    }

  updateNewNoteInput = (e) => {
      this.setState({
          newNoteInput: e.target.value
      })
    }

  updateNewVersionCode = (e) => {
    this.setState({
        newVersionCode: e.target.value
    })
    }

  updateVersionCode = (e) => {
    this.setState({
        versionCode: e.target.value
    })
  }

    enterKeyDown = (e) => {
        if (e.which === 13) {
          if (e.target.name == "edit-note") {
            this.submitChanges();
          } else if (e.target.name == "COTPortalChanges" || e.target.name == "BDPortalChanges") {
            this.submitNewNote(e);
          }
        }
    }

    render() {
        return (
            <div className="grid grid-gap-20">
                <Navigation />
                <Container>
                    <div style={{display: 'grid', width: '50%', gridTemplateColumns: '2fr 2fr 1fr', gridGap: '15px', textAlign: 'center'}}>
                        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                            <div style={{whiteSpace: 'nowrap', backgroundColor:'var(--primary)', color: '#fff', padding:'4px', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}}>Version Number</div>
                            <select style={{border: '1px solid var(--primary)', borderRadius: '5px'}} onChange={(_) => this.setState({selectedVersionNumber: _.target.value})} value={this.state.selectedVersionNumber}>
                                {
                                    session.company === null ?
                                        this.state.versionNumbers ? this.state.versionNumbers.map((version) => {
                                            return (
                                                <option value={version}>{version}</option>
                                            )
                                        })
                                        : null
                                    : session.company?.branding?.name === "COT Portal" ?
                                        this.state.versionNumbers ? this.state.versionNumbers.map((version) => {
                                            if (this.state.changes.filter(change => change.Version_Number === version && change.COT_Portal_Changes != null).length) {
                                                return (
                                                    <option value={version}>{version}</option>
                                                )
                                            }
                                        })
                                        : null
                                    : session.company?.branding?.name === "BD Portal" ?
                                        this.state.versionNumbers ? this.state.versionNumbers.map((version) => {
                                            if (this.state.changes.filter(change => change.Version_Number === version && change.BD_Portal_Changes != null).length) {
                                                return (
                                                    <option value={version}>{version}</option>
                                                )
                                            }
                                        })
                                        : null
                                    : null
                                }
                            </select>
                        </div>
                        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                        <div style={{whiteSpace: 'nowrap', backgroundColor:'var(--primary)', color: '#fff', padding:'4px', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}}>Limit</div>
                            <select style={{border: '1px solid var(--primary)', borderRadius: '5px'}} onChange={(_) => this.setState({limit: _.target.value})} value={this.state.limit}>
                                <option value={1}>1</option>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                            </select>
                        </div>
                        <button style={{color: 'white', backgroundColor: 'var(--primary)', border: 'none', cursor: 'pointer', borderRadius: '5px'}} onClick={() => {this.updateResults()}}>Update Results</button>
                    </div>
                    {
                        this.state.changes && !this.state.loading ? 
                        <>
                          <div style={this.state.changes.filter(change => change.COT_Portal_Changes != null && change.COT_Portal_Changes != "").length && this.state.changes.filter(change => change.BD_Portal_Changes != null && change.BD_Portal_Changes != "").length || !session.company ? {display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '1%'} : null}>
                            { session.company?.branding?.name === "COT Portal" ?
                                        <h3>COT Portal Changes</h3>
                                    :
                                        session.company === null ?
                                            <div>
                                                <h3>COT Portal Changes</h3>
                                                <button style={{color: 'white', backgroundColor: 'var(--primary)', border: 'none', cursor: 'pointer', borderRadius: '5px', padding: '5px'}} onClick={() => {this.setState({createNewNoteMode: "COTPortal"})}}>Create New Version Note</button>
                                            </div>
                                        :
                                            <div></div>
                                        
                                }
                              { session.company?.branding?.name === "BD Portal" ?
                                    <h3>BD Portal Changes</h3>
                                :
                                     session.company === null ?
                                        <div>
                                            <h3>BD Portal Changes</h3>
                                            <button style={{color: 'white', backgroundColor: 'var(--primary)', border: 'none', cursor: 'pointer', borderRadius: '5px', padding: '5px'}} onClick={() => {this.setState({createNewNoteMode: "BDPortal"})}}>Create New Version Note</button>
                                        </div>
                                    :
                                    <div></div>
                                    
                              }
                              { ((session.company && session.company?.branding?.name === "COT Portal") || !session.company) && this.state.changes.filter(change => change.COT_Portal_Changes != (null || "")).length || this.state.createNewNoteMode === "COTPortal" ?
                                <div>
                                    { this.state.createNewNoteMode === "COTPortal" ?
                                        <div style={{borderBottom: 'dashed 1px grey'}}>
                                            <input name="version-code-cot" value={this.state.newVersionCode ? this.state.newVersionCode : null} onChange={this.updateNewVersionCode} placeholder="Version Code" style={{display: 'block', fontSize: '1.17em', marginBlockStart: '1em', marginBlockEnd: '1em', marginInlineStart: '0px', marginInlineEnd: '0px', fontWeight: 'bold'}}></input>
                                            { this.state.newVersionCode && !/^\d+\.\d+\.\d+$/.test(this.state.newVersionCode) ? <p style={{color: 'red',  fontSize: 'small'}}>Version codes must follow a semantic versioning pattern such as 1.2.3</p> : null }
                                            <div style={{position: 'relative'}}>
                                                <textarea name="COT_Portal_Changes" onChange={ this.updateNewNoteInput } value={this.state.newNoteInput ? this.state.newNoteInput : null} style={{marginTop:'1em', marginBottom: '1em', minHeight: '20vh', width: '75%', resize: 'none'}} placeholder="Enter changes here"></textarea>
                                                <span style={{position: 'absolute', bottom: '3vh', right: '10vw', fontSize: 'small'}}>{this.state.newNoteInput ? 255 - this.state.newNoteInput.length : 255} characters remaining</span>
                                            </div>
                                            <div style={{display: 'flex', gap: '2%', marginBottom: '2%'}}>
                                                <i title="COT_Portal_Changes" class="fa-solid fa-check" style={{cursor: 'pointer'}} onClick={(e) => this.state.newNoteInput ? this.state.newNoteInput.length < 256 ? this.submitNewNote(e) : window.alert('Version notes too large') : this.submitNewNote(e)}></i>
                                                <i class="fa-solid fa-xmark" style={{cursor: 'pointer'}} onClick={() => this.setState({createNewNoteMode: false})}></i>
                                            </div>
                                        </div>
                                        :
                                        null
                                    } 
                                    {
                                            this.state.changes.filter(change => change.COT_Portal_Changes != null && change.COT_Portal_Changes != "").map((change) => {
                                            return (
                                                <div style={{borderBottom: 'dashed 1px grey'}}>
                                                    { this.state.editMode && this.state.noteToEdit === change.id ?
                                                            <div>
                                                                <input value={this.state.versionCode ? this.state.versionCode : change.Version_Number} onChange={this.updateVersionCode} style={{display: 'block', fontSize: '1.17em', marginBlockStart: '1em', marginBlockEnd: '1em', marginInlineStart: '0px', marginInlineEnd: '0px', fontWeight: 'bold', width: '20%'}}></input>
                                                                { this.state.versionCode && !/^\d+\.\d+\.\d+$/.test(this.state.versionCode) ? <p style={{color: 'red',  fontSize: 'small'}}>Version codes must follow a semantic versioning pattern such as 1.2.3</p> : null }
                                                                <div style={{position: 'relative'}}>
                                                                    <textarea name="edit-note" onChange={ this.updateEditInput } value={this.state.editInput ? this.state.editInput : change.COT_Portal_Changes} style={{marginTop:'1em', marginBottom: '1em', minHeight: '20vh', width: '75%', resize: 'none'}}></textarea>
                                                                    <span style={{position: 'absolute', bottom: '3vh', right: '10vw', fontSize: 'small'}}>{this.state.editInput ? 255 - this.state.editInput.length : 255 - change.COT_Portal_Changes.length} characters remaining</span>
                                                                </div>
                                                                { session.company === null ?
                                                                <div style={{display: 'flex', gap: '2%', marginBottom: '2%'}}>
                                                                    <i title="COT_Portal_Changes" class="fa-solid fa-check" style={{cursor: 'pointer'}} onClick={(e) => this.state.editInput ? this.state.editInput.length < 256 ? this.submitChanges(e) : window.alert('Version notes too large') : this.submitChanges(e)}></i>
                                                                    <i class="fa-solid fa-xmark" style={{cursor: 'pointer'}} onClick={() => this.setEditMode()}></i>
                                                                </div>
                                                                :
                                                                    null
                                                                }
                                                            </div>
                                                        :
                                                            <div>
                                                                <h3>{change.Version_Number ? change.Version_Number : null}</h3>
                                                                <p style={{whiteSpace: 'break-spaces'}}>{change.COT_Portal_Changes ? change.COT_Portal_Changes : null}</p>
                                                                { session.company === null ?
                                                                <div style={{display: 'flex', gap: '2%', marginBottom: '2%'}}>
                                                                    <i class="fa-light fa-pencil" style={{cursor: 'pointer'}} onClick={() => this.state.editMode ? null : this.setEditMode(change.id)}></i>
                                                                    <i class="fa-light fa-trash-can" style={{cursor: 'pointer'}} onClick={() => this.state.editMode ? null : this.deleteChanges(change.id)}></i>
                                                                </div>
                                                                :
                                                                    null
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                  :
                                  <div></div>
                              }
                              { ((session.company && session.company?.branding?.name === "BD Portal") || !session.company) && this.state.changes.filter(change => change.BD_Portal_Changes != null && change.BD_Portal_Changes != "").length || this.state.createNewNoteMode === "BDPortal" ?
                                <div>
                                    { this.state.createNewNoteMode === "BDPortal" ?
                                        <div style={{borderBottom: 'dashed 1px grey'}}>
                                            <input name="version-code-bd" value={this.state.newVersionCode ? this.state.newVersionCode : null} onChange={this.updateNewVersionCode} placeholder="Version Code" style={{display: 'block', fontSize: '1.17em', marginBlockStart: '1em', marginBlockEnd: '1em', marginInlineStart: '0px', marginInlineEnd: '0px', fontWeight: 'bold'}}></input>
                                            { this.state.newVersionCode && !/^\d+\.\d+\.\d+$/.test(this.state.newVersionCode) ? <p style={{color: 'red',  fontSize: 'small'}}>Version codes must follow a semantic versioning pattern such as 1.2.3</p> : null }
                                            <div style={{position: 'relative'}}>
                                                <textarea name="BD_Portal_Changes" onChange={ this.updateNewNoteInput } value={this.state.newNoteInput ? this.state.newNoteInput : null} style={{marginTop:'1em', marginBottom: '1em', minHeight: '20vh', width: '75%', resize: 'none'}} placeholder="Enter changes here"></textarea>
                                                <span style={{position: 'absolute', bottom: '3vh', right: '10vw', fontSize: 'small'}}>{this.state.newNoteInput ? 255 - this.state.newNoteInput.length : 255} characters remaining</span>
                                            </div>
                                            <div style={{display: 'flex', gap: '2%', marginBottom: '2%'}}>
                                                <i title="BD_Portal_Changes" class="fa-solid fa-check" style={{cursor: 'pointer'}} onClick={(e) => this.state.newNoteInput ? this.state.newNoteInput.length < 256 ? this.submitNewNote(e) : window.alert('Version notes too large') : this.submitNewNote(e)}></i>
                                                <i class="fa-solid fa-xmark" style={{cursor: 'pointer'}} onClick={() => this.setState({createNewNoteMode: false})}></i>
                                            </div>
                                        </div>
                                        :
                                        null
                                    } 
                                    {
                                        this.state.changes.filter(change => change.BD_Portal_Changes != null && change.BD_Portal_Changes != "").map((change) => {
                                            return (
                                                <div style={{borderBottom: 'dashed 1px grey'}}>
                                                    { this.state.editMode && this.state.noteToEdit === change.id ?
                                                        <div>
                                                            <input value={this.state.versionCode ? this.state.versionCode : change.Version_Number} onChange={this.updateVersionCode} style={{display: 'block', fontSize: '1.17em', marginBlockStart: '1em', marginBlockEnd: '1em', marginInlineStart: '0px', marginInlineEnd: '0px', fontWeight: 'bold', width: '20%'}}></input>
                                                            { this.state.versionCode && !/^\d+\.\d+\.\d+$/.test(this.state.versionCode) ? <p style={{color: 'red',  fontSize: 'small'}}>Version codes must follow a semantic versioning pattern such as 1.2.3</p> : null }
                                                            <div style={{position: 'relative'}}>
                                                                <textarea name="edit-note" onChange={ this.updateEditInput } value={this.state.editInput ? this.state.editInput : change.BD_Portal_Changes} style={{marginTop:'1em', marginBottom: '1em', minHeight: '20vh', width: '75%', resize: 'none'}}></textarea>
                                                                <span style={{position: 'absolute', bottom: '3vh', right: '10vw', fontSize: 'small'}}>{this.state.editInput ? 255 - this.state.editInput.length : 255 - change.BD_Portal_Changes.length} characters remaining</span>
                                                            </div>
                                                            { session.company === null ?
                                                                <div style={{display: 'flex', gap: '2%', marginBottom: '2%'}}>
                                                                    <i title="BD_Portal_Changes" class="fa-solid fa-check" style={{cursor: 'pointer'}} onClick={(e) => this.state.editInput ? this.state.editInput.length < 256 ? this.submitChanges(e) : window.alert('Version notes too large') : this.submitChanges(e)}></i>
                                                                    <i class="fa-solid fa-xmark" style={{cursor: 'pointer'}} onClick={() => this.setEditMode()}></i>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        :
                                                        <div>
                                                            <h3>{change.Version_Number ? change.Version_Number : null}</h3>
                                                            <p style={{whiteSpace: 'break-spaces'}}>{change.BD_Portal_Changes ? change.BD_Portal_Changes : null}</p>
                                                            { session.company === null ?
                                                                <div style={{display: 'flex', gap: '2%', marginBottom: '2%'}}>
                                                                    <i class="fa-light fa-pencil" style={{cursor: 'pointer'}} onClick={() => this.state.editMode ? null : this.setEditMode(change.id)}></i>
                                                                    <i class="fa-light fa-trash-can" style={{cursor: 'pointer'}} onClick={() => this.state.editMode ? null : this.deleteChanges(change.id) }></i>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }

                                    </div>
                                  :
                                  <div></div>
                              }
                          </div>
                        </>
                        :
                            <i className='fas fa-loader fa-fw fa-spin'></i>
                    }
                </Container>
                <Footer/>
            </div>
        )
    }
})
