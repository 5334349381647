import React from 'react';
import Modal from 'react-modal';
import { useEffect } from 'react';
import request from '../../helpers/request';
import './bulkBuy.scss';

export default function BulkBuy ({ 
    modalIsOpen, closeModal, filters, date, 
    workflowUsersEndpoint, bulkEndpoint, 
    stepOneType, stepTwoType, stepThreeType, 
    SentenceFirst, SentenceSecond,
    buyButtonText = 'Buy Now',
    completeOnFirstStep = false,
    mode,
    ...props })
{
    const [step, setStep] = React.useState(1);
    const [bulkCount, setBulkCount] = React.useState(0);
    const [bulkLimit, setBulkLimit] = React.useState(0);
    const [buyNowEnabled, setBuyNowEnabled] = React.useState(true);
    const [error, setError] = React.useState(undefined);

    useEffect(() => {
        setStep(1);
    }, [modalIsOpen, setStep]);

    useEffect(() => {
        if (step === 1 && modalIsOpen) {
          request(true).get(bulkEndpoint, {
                params: {
                    date: date,
                    filter: filters,
                    mode: mode,
                }
            }).then(r => {
                setBulkCount(r.data);
                setBulkLimit(r.data);
            }).catch(e => {
                console.log(e);
            })
        }
    }, [step, modalIsOpen, filters, date, bulkEndpoint]);

    const confirmPurchase = () => {
        console.log('click');
        setBuyNowEnabled(false);
        buy();
    }

    const buy = () => {
        if (bulkLimit <= 0) return;
        request(true).post(bulkEndpoint, {
            date: date,
            filter: filters,
            limit: bulkLimit,
            mode: mode
        }).then(r => {
            window.location.reload();
        }).catch(e => {
            console.log(e);
        });
    }

    const formChange = (value) => {
        value = Number(value)
        if (value === "") return setBulkLimit(0)

        if (value > bulkCount) {
            value = bulkCount;
        }

        setBulkLimit(value);
    }

    const firstBuyStep = () => {
        console.log('firstBuyStep', bulkLimit, bulkLimit === 0, bulkLimit === '0')
        if (bulkLimit === 0) return setError('You cannot buy 0 customers, you must buy 1 or more.')
        setError(undefined)
        if (completeOnFirstStep) {
            confirmPurchase()
        } else {
            setStep(2)
        }
    }

    return (
        <Modal
                style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                className="card bulk-buy"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Bulk Buy COT Alert Data"
                appElement={document.getElementById('root')}
            >
            { step === 1 ?
                <div className='bbmodal-text'>
                {
                    SentenceFirst ? <>
                        <SentenceFirst count={bulkCount} />
                    </> : <>
                        <p>
                            The selected data set includes { bulkCount } { stepOneType || 'customers' }. How many would you like to purchase?
                        </p>
                        <small>
                            If you reduce this number then { stepOneType || 'customers'} with higher { stepTwoType || 'occupier' } scores will be purchased first.
                        </small>
                        <small>
                            Customers with an { stepThreeType || 'occupier' } score of 0 cannot be purchased.
                        </small>    
                    </>
                }
                    {error ? <p className="colour-yellow"><strong>{error}</strong></p> : null} 
                    <div className="input">
                        <label>Purchase Limit:</label>
                        <input type="number" name="bulk-limit" max={ bulkCount } value={ bulkLimit } onChange={ (e) => formChange(e.target.value) }/>
                    </div>
                    <div className="grid-2">
                        <button className="button cancel" onClick={ closeModal }>Cancel</button>
                        <button className="button confirm" onClick={ () => firstBuyStep() }>
                            
                            {
                                !buyNowEnabled ?
                                    <div className="spinner">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                : completeOnFirstStep ? buyButtonText : 'Confirm'
                            }
                        </button>
                    </div>
                </div>
            :
            <>
                {
                    SentenceSecond ? <>
                        <SentenceSecond count={bulkLimit} />
                    </> : <>
                        <p>
                            You have selected { bulkLimit } accounts to purchase, please press '{buyButtonText}' to complete this transaction.
                        </p>
                    </>
                }
                <div className="grid-2">
                    <button className="button cancel" onClick={ closeModal }>Cancel</button>
                    <button className="button confirm" onClick={ confirmPurchase } disabled={ !buyNowEnabled }>
                        {
                            !buyNowEnabled ?
                                <div className="spinner">
                                    <i className="fa fa-spinner fa-spin"></i>
                                </div>
                            : buyButtonText
                        }
                    </button>
                </div>
            </>
            }
        </Modal>
    )
}
