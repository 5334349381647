import React from 'react'
import Container from '../components/container/container'
import Footer from '../components/footer/footer'
import Navigation from '../components/navigation/navigation'
import PageTitle from '../components/page-header/pageTitle'

export default function GenericPageContainer({
  title,
  children,
  className,
  titleRight,
  titleIconClass,
  containerWidth
}) {
  return (
    <div className={['grid grid-gap-15', className].join(' ')}>
      <Navigation />
      <Container width={containerWidth}>
        <PageTitle iconClass={titleIconClass} title={title} titleRight={<div style={{display: 'flex'}}>{titleRight}</div>} />
        {children}
      </Container>
      <Footer />
    </div>
  )
}
