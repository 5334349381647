import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Container from '../../components/container/container'
import Navigation from '../../components/navigation/navigation'
import ProductSelect from '../../components/product-select/productSelect'
import request from '../../helpers/request'
import session from '../../stores/session'

export default function DataFilters() {
  const [dataFilters, setDataFilters] = useState([])
  const [company] = useState(undefined)
  const [product, setProduct] = useState(undefined)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setProduct(session.filterSelectedProduct)
  }, [])
  
  const getDataFilters = () => {
    const filterQuery = new URLSearchParams()
    if (company) filterQuery.append('company_id', company)
    if (product) filterQuery.append('real_product_id', product)
    setLoading(true)

    request(true).get('/datafilters?' + filterQuery.toString()).then(data => {
      if (data.status === 200) {
        setDataFilters(data.data);
        setLoading(false)
      }
    }).catch(error => {
      console.log(error)
      setDataFilters([])
      setLoading(false)
      window.alert("Could not retrieve filters")
    })
  }

  const addQueryData = useEffect(() => {
    session.filterSelectedProduct = product
    if (product !== undefined) {
      getDataFilters();
    } else {
      setDataFilters([])
    }
  }, [product, company])

  const deleteFilter = (id) => {
    if (!window.confirm('Delete filter?')) return
    request(true).delete('/datafilters/' + id).then(() => {
      getDataFilters()
    })
  }

  const copyFilter = (id) => {
    request(true).post(`/datafilters/${id}/copy`, {
      newName: window.prompt('Copy Name Key')
    }).then(() => {
      getDataFilters()
      window.alert('Coppied')
    }).catch(() => {
      window.alert('Failed to copy')
    })
  }

  return (
    <div className="grid grid-gap-20">
      <Navigation />
      <Container>
          <div className="flex middle">
              <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Data Filters</p>
              <div className="flex" style={{ marginLeft: 'auto', gap: '10px' }}>
                <div>
                  <Link to="/admin/portal/filters/new"><button className="button compact background-primary colour-white">New Data Filter</button></Link>
                </div>
                {/* <div>
                  <label>Company</label>
                  <CompanySelect onChange={(e) => setCompany(e ? e.value : undefined)} />
                </div> */}
                <div style={{display: 'flex'}}>
                  <ProductSelect product={product} onChange={(e) => setProduct(e ? e.value : undefined)} />
                </div>
              </div>
          </div>
      </Container>
      <Container>
        <table className="table">
          <thead>
            <tr>
              <th>Internal Name</th>
              <th>Title</th>
              <th>Targets</th>
              {/* <th>Default?</th> */}
              {/* <th>Company</th> */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            { 
              loading ? 
                <td colSpan={4} style={{ textAlign: 'center' }} >
                  <i className="fa fa-spinner fa-spin" style={{ margin: '1em' }} />
                </td>
              : ''
            }
            {
              dataFilters.length ? dataFilters.map(row => {
                return <tr key={'filter_' + row.id}>
                  <td>{row.internal_name}</td>
                  <td>{row.title}</td>
                  <td>{row.target_column}</td>
                  {/* <td>{row.default ? <strong className="colour-primary">Default</strong> : <small className="colour-yellow">Not Default</small>}</td> */}
                  {/* <td>{row.company_id ? <strong className="colour-primary">{row.company.name}</strong> : <small className="colour-yellow">None</small>}</td> */}
                  <td className="grid grid-columns-3 grid-gap-10">
                    <Link to={`/admin/portal/filters/${row.id}`}><button className="button compact background-primary colour-white">Edit</button></Link>
                    <button onClick={() => deleteFilter(row.id)} className="button compact background-yellow colour-white">Delete</button>
                    <button className='button compact' onClick={() => copyFilter(row.id)}><span className='fas fa-copy'></span></button>
                  </td>
                </tr>
              }) : null
            }
          </tbody>
        </table>
      </Container>
    </div>
  )
}
