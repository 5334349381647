import React, { useEffect, useState, Component } from 'react';
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import request from '../../helpers/request';
import endpoints from '../../helpers/endpoints';
import Pagination from '../../components/pagination/pagination';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import { BarChart, XAxis, YAxis, Legend, Bar, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import Text from 'recharts/lib/component/Text';

import Moment from 'moment';
import Select from 'react-select';
import FileSaver from 'file-saver';
import moment from 'moment';

export default function Usage (props) {
    const { routeTarget } = props;
    const [events, setEvents] = useState(null)
    const [userEvents, setUserEvents] = useState([])
    const [users, setUsers] = useState([])
    const [modules, setModules] = useState([])
    const [companies, setCompanies] = useState([])
    const [mode, setMode] = useState("Table");
    const [filters, setFilters] = useState({})
    const [sortingConfig, setSortingConfig] = useState({event: 'DESC', count: 'DESC'})
    const [fromTime, setFromTime] = useState(null)
    const [toTime, setToTime] = useState(null)
    const [loading, setLoading] = useState(true);
    const [summaryData, setSummaryData] = useState(null);
    const [graphData, setGraphData] = useState(null);

    const localised = Intl.NumberFormat('en-GB');

    const CustomizedAxisTickMultiLine = ({x, y, payload}) => {
        return (
           <Text x={x} y={y} width={120} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
        )
    };

    const colours = [
        "#445469",
        "var(--primary)",
        "#0d73b2",
        "#f19a14",
        "#d13358",
        "#b24c0d",
        "#DFBE99",
        "#C879FF",
        "#DEC5E3",
        "#0D1821",
        "#F9CB40",
        "#5ab530",
        "#c6c44e",
        "#f04a38",
        "#e5f333",
        "#1eb5c4",
        "#500fc6",
        "#ea0fa9",
        "#8e44ad",
        "#3498db",
        "#e74c3c",
        "#2ecc71",
        "#f39c12",
        "#c0392b",
        "#27ae60",
        "#d35400",
        "#2980b9",
        "#16a085",
        "#e74c3c",
        "#2c3e50",
        "#ecf0f1",
        "#95a5a6",
        "#7f8c8d",
        "#bdc3c7",
        "#34495e",
        "#d35400",
        "#3498db",
        "#e74c3c",
        "#2ecc71",
        "#f39c12"
      ]


    const downloadCSV = () => {
        const headers = ["Event", "Description", "Counts"];
        const data = events.map(event => {
            return Object.values(event).join(',');
        });

        const csv = [headers.join(','), ...data].join('\n');
      
        const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, 'usage.csv');
        } else {
          const link = document.createElement('a');
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'usage.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }

    const getEvents = () => {
        let filterString = "";
        if (Object.keys(filters).length) {
            for (const filter of Object.values(filters)) {
                filterString = filterString.concat(`&${filter}`)
            }
        }
        request(true).get(endpoints.USAGE + '?' + filterString, 
            {
                params: {
                    routeTarget: routeTarget
                }
            }
        ).then(r => {
            r.data.sort((a, b) => {
                return a.count < b.count ? 1 : a.count > b.count ? -1 : 0;
            });
            setEvents(r.data);
            setLoading(false)
        })
    }

    const getChart = () => {
        let filterString = "";
        if (Object.keys(filters).length) {
            for (const filter of Object.values(filters)) {
                filterString = filterString.concat(`&${filter}`)
            }
        }
        request(true).get(endpoints.USAGE + (routeTarget === "Usage" ? '/chart' : '/summary') + '?' + filterString).then(r => {
            if (routeTarget === "Usage") {
                setEvents(r.data.events);
                setUserEvents(r.data.users);
            } else {
                setSummaryData(r.data)
            }
            setLoading(false)
        })
    }

    const getUsers = () => {
        request(true).get(endpoints.USAGE + '/users', {
            params: {
                routeTarget: routeTarget
            }
        }).then(r => {
            setUsers(r.data);
        })
    }

    const getModules = () => {
        request(true).get(endpoints.USAGE + '/modules', {
            params: {
                routeTarget: routeTarget
            }
        }).then(r => {
            setModules(r.data);
        })
    }

    const getCompanies = () => {
        request(true).get(endpoints.USAGE + '/companies', {
            params: {
                routeTarget: routeTarget
            }
        }).then(r => {
            setCompanies(r.data);
        })
    }

    const sortData = (column) => {
        const sort = sortingConfig[column] === "ASC" ? "DESC" : "ASC"
        setSortingConfig({...sortingConfig, [column]: sort})
        events.sort((a, b) => {
            if (sort === "ASC") {
                return a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0;
            } 
                return a[column] < b[column] ? 1 : a[column] > b[column] ? -1 : 0;
        });
    }

    const userFilter = (options) => {
        const users = options.map(option => {return option.value});
        const filter = `users=${JSON.stringify(users)}`;
        setFilters({...filters, userFilter: filter})
    }

    const moduleFilter = (options) => {
        const modules = options.map(option => {return option.value});
        const filter = `modules=${JSON.stringify(modules)}`;
        setFilters({...filters, moduleFilter: filter})
    }

    const companyFilter = (options) => {
        const companies = options.map(option => {return option.value});
        const filter = `companies=${JSON.stringify(companies)}`;
        setFilters({...filters, companyFilter: filter})
    }

    useEffect(() => {
        const filter = `fromTime=${fromTime ? fromTime.toISOString() : null}&toTime=${toTime ? toTime.toISOString() : null}`
        setFilters({...filters, timeFilter: filter})
    }, [fromTime, toTime])

    useEffect(() => {
        getUsers()
        getModules()
        getCompanies()
    }, [])

    useEffect(() => {
        if (!summaryData) return
        generateGraphData();
    }, [summaryData])

    useEffect(() => {
        if (mode === "Table") {
            setLoading(true)
            getEvents()
        } else {
            setLoading(true)
            getChart()
        }
    }, [mode])

    const cx = 200;
    const cy = 200;
    const radius = 160;

    const eventChart = events?.map((event, index) => {
        const total = events.reduce((acc, item) => acc + item.count, 0);

        const percentage = (event.count / total) * 100;
        const startAngle = (index === 0) ? 0 : events.slice(0, index).reduce((acc, val) => acc + (val.count / total) * 360, 0);
        const endAngle = startAngle + (percentage / 100) * 360;

        const x1 = cx + radius * Math.cos((startAngle - 90) * (Math.PI / 180));
        const y1 = cy + radius * Math.sin((startAngle - 90) * (Math.PI / 180));
        const x2 = cx + radius * Math.cos((endAngle - 90) * (Math.PI / 180));
        const y2 = cy + radius * Math.sin((endAngle - 90) * (Math.PI / 180));

        const largeArcFlag = percentage > 50 ? 1 : 0;

        return (
            <path
            key={index}
            d={`M ${cx},${cy} L ${x1},${y1} A ${radius},${radius} 0 ${largeArcFlag},1 ${x2},${y2} Z`}
            fill={`hsl(${index * 60}, 80%, 60%)`}
            />
        )
    })

    const userChart = userEvents.map((user, index) => {
        const total = userEvents.reduce((acc, item) => acc + item.count, 0);

        const percentage = (user.count / total) * 100;
        const startAngle = (index === 0) ? 0 : userEvents.slice(0, index).reduce((acc, val) => acc + (val.count / total) * 360, 0);
        const endAngle = startAngle + (percentage / 100) * 360;

        const x1 = cx + radius * Math.cos((startAngle - 90) * (Math.PI / 180));
        const y1 = cy + radius * Math.sin((startAngle - 90) * (Math.PI / 180));
        const x2 = cx + radius * Math.cos((endAngle - 90) * (Math.PI / 180));
        const y2 = cy + radius * Math.sin((endAngle - 90) * (Math.PI / 180));

        const largeArcFlag = percentage > 50 ? 1 : 0;

        const path = `M ${cx},${cy} L ${x1},${y1} A ${radius},${radius} 0 ${largeArcFlag},1 ${x2},${y2} Z`

        if (userEvents.length > 1) {
            return (
                <path
                    key={index}
                    d={path}
                    fill={`hsl(${index * 60}, 80%, 60%)`}
                />
            )
        } 
            return (
                <path
                    key={index}
                    d="M200,200 m0,-160 a160,160 0 1,1 0,320 a160,160 0 1,1 0,-320"
                    fill={`hsl(${index * 60}, 80%, 60%)`}
                />
            )
    })

    const generateGraphData = () => {
        const data = [];
    
        const earliestDate = fromTime ? null : moment(Object.keys(summaryData.data[summaryData.keys[0]])[0], 'MM-YYYY').startOf('month')

        const startDate = fromTime ? moment(fromTime).startOf('month') : earliestDate;
        const endDate = toTime ? moment(toTime).endOf('month') : moment().endOf('month');

        while (startDate < endDate) {
           data.push({name: startDate.format('MM-YYYY')});
           startDate.add(1,'month');
        }

        const dataToProcess = {...summaryData}

        dataToProcess.keys.map(key => {
            Object.keys(dataToProcess.data[key]).map((item, index) => {
                data[data.findIndex(_ => _.name == item)][key] = dataToProcess.data[key][item];

                return dataToProcess.data[key][item];
            });

            return key;
        });

        setGraphData(data)
    }

    useEffect(() => {
        if (events === null) return
        if (mode === "Table") {
            getEvents()
        } else {
            getChart()
        }
    }, [filters])
    
    useEffect(() => {
        if (!events) return
        setSortingConfig({event: 'DESC', count: 'DESC'})
    }, [events])

    return (
        <div className="grid grid-gap-20">
            <Navigation/>
            <Container>
                <div className="flex middle" style={{ justifyContent: 'space-between' }}>
                    <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>{routeTarget}</p>
                    <div className='grid grid-columns-2 grid-gap-5'>
                        <button className="button background-primary colour-white" style={{padding: '10px 5px 10px 5px'}} onClick={() => { setMode(mode === "Table" ? "Charts" : "Table"); setLoading(true) }}>{mode === "Table" ? "Charts" : "Table"}</button>
                        <button className="button background-primary colour-white" style={{padding: '10px 5px 10px 5px'}} onClick={downloadCSV}>Download CSV</button>
                    </div>
                </div>
            </Container>
            <Container>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', gap: '15px', marginBottom: '15px'}}>
                    <div>
                        User
                        <Select id="users" options={users} isMulti={true} onChange={ userFilter } />
                    </div>
                    <div>
                        Product
                        <Select options={modules} isMulti={true} onChange={ moduleFilter } />
                    </div>
                    <div>
                        Company
                        <Select options={companies} isMulti={true} onChange={ companyFilter } />
                    </div>
                    {
                        routeTarget === "Purchases" && mode === "Charts" ?
                            <>
                                <DatePicker variant="inline" views={['year', 'month']} format="DD-MM-yyyy" openTo="month"  label="From:" value={fromTime} onChange={setFromTime}/>
                                <DatePicker variant="inline" views={['year', 'month']} format="DD-MM-yyyy" openTo="month"  label="To:" value={toTime} onChange={setToTime}/>    
                            </>
                        :
                            <>
                                <DateTimePicker variant="inline" label="From:" value={fromTime} onChange={setFromTime}/>
                                <DateTimePicker variant="inline" label="To:" value={toTime} onChange={setToTime}/>
                            </>
                    }
                </div>
                { loading ? 
                        <i className='fas fa-loader fa-fw fa-spin'></i>
                    :
                        mode === "Table" ?
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{textAlign: 'center'}} onClick={() => sortData("event")}>{routeTarget === "Usage" ? "Event" : "Purchase" }<i className={"fa-solid fa-sort-" + (sortingConfig.event === "ASC" ? "up" : "down")}></i></th>
                                        <td style={{textAlign: 'center'}}>{routeTarget === "Usage" ? "Description" : "Name"}</td>
                                        <th style={{textAlign: 'center'}} onClick={() => sortData("count")}>{routeTarget === "Usage" ? "Counts" :  "Volume"}<i className={"fa-solid fa-sort-" + (sortingConfig.count === "ASC" ? "up" : "down")}></i></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        events ? 
                                        events.map((event, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{event.event}</td>
                                                        <td>{event.info}</td>
                                                        <td>{event.count}</td>
                                                    </tr>
                                                )
                                            })
                                        :
                                            null
                                    }
                                    <tr>
                                        <td>Total</td>
                                        <td></td>
                                        <td>{events ? events.reduce((acc, item) => acc + item.count, 0) : null}</td>
                                    </tr>
                                </tbody>
                            </table>
                        :
                            routeTarget === "Usage" ?
                                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1em'}}>
                                    <div>
                                        <h2>Events</h2>
                                        <div style={{display: 'flex', fontSize: 'small'}}>
                                            <svg width="400" height="400">
                                                {eventChart}
                                            </svg>
                                            <ul style={{overflow: 'auto', maxHeight: '28em', padding: '0px'}}>
                                                {events.map((event, index) => {
                                                    return (
                                                        <li style={{listStyleType: 'none', display: 'flex', alignItems: 'center', gap: '10px'}}>
                                                            <div style={{backgroundColor: `hsl(${index * 60}, 80%, 60%)`, width: '10px', height: '10px'}} />
                                                            {event.event}
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div>
                                        <h2>Users</h2>
                                        <div style={{display: 'flex', fontSize: 'small'}}>
                                            <svg width="400" height="400">
                                                {userChart}
                                            </svg>
                                            <ul style={{overflow: 'auto', maxHeight: '28em', padding: '0px'}}>
                                                {userEvents.map((user, index) => {
                                                    return (
                                                        <li style={{listStyleType: 'none', display: 'flex', alignItems: 'center', gap: '10px'}}>
                                                            <div style={{backgroundColor: `hsl(${index * 60}, 80%, 60%)`, width: '10px', height: '10px'}} />
                                                            {user.name} - {user.company_name}
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            :
                                <>
                                    {
                                        !loading && graphData ? 
                                            <div style={{minHeight: '60vh'}}>
                                                <ResponsiveContainer width="100%" >
                                                    <BarChart data={graphData} barSize={50} margin={{ bottom: 20 }}>
                                                        <Legend margin={{top: 20}} iconType="circle" iconSize={11}   wrapperStyle={{
                                                            padding: '0 2rem',
                                                            height: '20%',
                                                            overflow: 'auto',
                                                        }} />
                                                        <XAxis interval={0} dataKey="name" type="category" tick={CustomizedAxisTickMultiLine} />
                                                        <YAxis
                                                            type="number"
                                                            allowDecimals={false}
                                                            tick={{ fontSize: 10 }}
                                                            tickFormatter={(item) => localised.format(item)}
                                                        />
                                                        <CartesianGrid  />
                                                        {summaryData.keys.map((item, index) => (
                                                            <Bar
                                                            key={`graph_bar_${item}`}
                                                            dataKey={item}
                                                            fill={colours[index]}
                                                            stackId="a"
                                                            />
                                                        ))}
                                                        <Tooltip
                                                            cursor={false}
                                                            formatter={(value, name, props) => localised.format(value)}
                                                        />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        :
                                            <i className='fas fa-loader fa-fw fa-spin'></i>
                                    }
                                </>
                }
            </Container>
            <Footer/>
        </div>
    )
}
