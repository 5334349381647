import axios from 'axios';
import session from '../stores/session';

export default (authed = false) => {
    const obj = {
        baseURL: sessionStorage.getItem('api-path')
    };

    if (authed) {
        obj.headers = {
            Authorization: 'Bearer ' + sessionStorage.getItem('user-token')
        }
    }

    const ax = axios.create(obj);

    ax.interceptors.response.use(
        res => res,
        err => {
            console.log(err.response);

            if (err.response.status === 403 || err.response.status === 401) {
                console.log('Hit a 401 or 403, resetting session. bad 🗞')
                session.reset();
                // window.alert(401)
            }

            throw err;
        }
    )

    return ax;
}
