import React, { Component } from 'react'
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import Modal from 'react-modal';

import request from '../../helpers/request';
import endpoints from '../../helpers/endpoints';
import { Link } from 'react-router-dom';

export default class ProductsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            realProducts: [],
            modalIsOpen: false,
            product: {
                name: '',
                description: '',
                icon: '',
                available: false,
            },
            errors: {}
        }
    }

    updatePage = (page) => {
        this.loadPage(page);
    }

    loadPage = (page = 1) => {
        request(true).get(endpoints.ADMIN_PRODUCTS_INDEX).then(e => {
            //console.log(e.data);
            this.setState({
                products: e.data,
            });
        }).catch(e => {
            //console.log(e.response);
        })
    }

    componentDidMount() {
        this.loadPage();
        this.loadRealProducts();
    }

    openModal = () => {
        this.setState({ modalIsOpen: true });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    updateFormValue = (e) => {
        let value = e.target.value ? e.target.value : undefined

        if (e.target.value === "true") {
            value = true;
        } else if (e.target.value === "false") {
            value = false;
        }

        this.setState({
            product: {
                ...this.state.product,
                [e.target.name]: value
            }
        })
    }

    formSubmit = () => {
        request(true).post(endpoints.ADMIN_PRODUCTS_INDEX, this.state.product).then(r => {
            this.loadPage(1);
            this.setState({
                product: {
                    name: '',
                    icon: '',
                    description: '',
                    available: false
                }
            });
        }).catch(r => {
            //console.log(r.response);
            this.setState({ errors: r.response.data.errors ?? {}})
        })
    }

    loadRealProducts = () => {
        request(true).get(endpoints.ADMIN_REAL_PRODUCTS_INDEX).then(r => {
            //console.log(r);
            this.setState({
                realProducts: r.data
            })
        });
    }

    updateProductState = (index, event) => {
        const temp = [].concat(this.state.realProducts);
        temp[index].product_offering_id = event.target.value;

        this.setState({
            realProducts: temp
        });
    }

    submitRealProducts = (event) => {
        event.preventDefault();

        const postData = {
            realProducts: this.state.realProducts.map(item => {
                return {
                    id: item.id,
                    product_offering_id: item.product_offering_id
                }
            })
        }

        request(true).post(endpoints.ADMIN_REAL_PRODUCTS_INDEX, postData).then(r => {
            //console.log(r);
        }).catch(e => {
            //console.log(e);
        })
    }

    render() {
        return (
            <div className="grid grid-gap-20">
                <Navigation/>
                <Container>
                    <div className="flex middle">
                        <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Products</p>
                        <button onClick={this.openModal} style={{ marginLeft: 'auto' }} className="button background-primary colour-white">Add A Product</button>
                    </div>
                </Container>
                <Container>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.products.map(product => {
                                return (
                                    <tr key={product.id}>
                                        <td>{ product.name }</td>
                                        <td className="actions">
                                            <Link to={ "/admin/portal/products/" + product.id }><i className="fa fa-search" aria-hidden="true"></i></Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </Container>
                <Container>
                    <form onSubmit={ this.submitRealProducts }>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Key</th>
                                    <th>URL</th>
                                    <th>Product Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.state.realProducts.map((product, index) => {
                                    return (
                                        <tr key={`real-product-${product.id}`}>
                                            <td>
                                                { product.product_key }
                                            </td>
                                            <td>
                                                { product.product_url}
                                            </td>
                                            <td>
                                                <select name={product.id} value={ product.product_offering_id } onChange={ (event) => { this.updateProductState(index, event) }}>
                                                    <option value={''}>
                                                        Select a Product
                                                    </option>
                                                    { this.state.products.map(item => {
                                                        return (
                                                            <option key={`${product.id}-${item.id}`} value={item.id}>
                                                                { item.name }
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <button style={{ marginTop: '20px', float: 'right' }} className="button background-primary colour-white">Update</button>
                    </form>
                </Container>
                <Modal
                    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                    className="card"
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Add A Company"
                    appElement={document.getElementById('admin-page')}
                >
                    <div className="grid grid-gap-15">
                        <div className="form-row">
                            <label htmlFor="name">Name:</label>
                            <input onChange={this.updateFormValue} name="name" placeholder="Name" className="input" type="text" value={this.state.product.name} required/>
                            { this.state.errors.name ? this.state.errors.name.map((value, index) => {
                                return (
                                    <p key={index}>
                                        { value }
                                    </p>
                                )
                            }) : <></>}
                        </div>
                        <div className="form-row">
                            <label htmlFor="icon">Icon:</label>
                            <input onChange={this.updateFormValue} name="icon" placeholder="Icon" className="input" type="text" value={this.state.product.icon} required/>
                            { this.state.errors.name ? this.state.errors.name.map((value, index) => {
                                return (
                                    <p key={index}>
                                        { value }
                                    </p>
                                )
                            }) : <></>}
                        </div>
                        <div className="form-row">
                            <label htmlFor="description">Description:</label>
                            <input onChange={this.updateFormValue} name="description" placeholder="Description" className="input" type="text" value={this.state.product.description} required/>
                            { this.state.errors.description ? this.state.errors.description.map((value, index) => {
                                return (
                                    <p className="error" key={index}>
                                        { value }
                                    </p>
                                )
                            }) : <></>}
                        </div>
                        <div className="form-row">
                            <label htmlFor="available">Available:</label>
                            <div className="item">
                                True: <input onChange={this.updateFormValue} name="available" className="input" type="radio" value={true} checked={this.state.product.available}/>
                            </div>
                            <div className="item">
                                False: <input onChange={this.updateFormValue} name="available" className="input" type="radio" value={false} checked={!this.state.product.available}/>
                            </div>
                            { this.state.errors.available ? this.state.errors.available.map((value, index) => {
                                return (
                                    <p className="error" key={index}>
                                        { value }
                                    </p>
                                )
                            }) : <></>}
                        </div>
                        <p className="text-center">{ this.state.message }</p>
                        <button onClick={this.formSubmit} className="button background-primary colour-white">Create</button>
                    </div>
                </Modal>
                <Footer />
            </div>
        )
    }
}
