import React, { Component } from 'react';
import Navigation from '../components/navigation/navigation'
import Footer from '../components/footer/footer'
import Container from '../components/container/container'
import DoResetComponent from '../components/login/doReset'
import HelpVideo from '../components/help-video/helpVideo';

export default class Reset extends Component
{
    render() {
        return (
            <div className="grid grid-gap-20 page-wrapper">
                <Navigation />
                <Container>
                    <DoResetComponent location={this.props.location} history={ this.props.history }/>
                    <HelpVideo video={sessionStorage.getItem('login-video-path')}/>
                </Container>
                <Footer />
            </div>
        );
    }
}
